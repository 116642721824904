import styled from '@emotion/styled';
import { breakpoints, colors, dimensions } from '../../styles/variables';
import illustration from '../../assets/illustration-background.svg';
import backgroundTop from '../../assets/backgrounds/biscuit-top.svg';
import backgroundBottom from '../../assets/backgrounds/biscuit-bottom.svg';
import { Visibility } from '../../types';;


export const SafariWrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;


export const Wrapper = styled.section<Visibility>`
  opacity: 0;
  display: flex;
  margin-top: 85px;
  position: relative;
  align-items: center;
  flex-direction: column;
  transform: translateY(10%);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

  ${({ isVisible }) =>
    isVisible &&
    `
    opacity: 1;
    transform: translateY(0);
  `}
`;

export const StickyDetection = styled.div`
  width: 100%;
  height: 1px;
  padding-top: 450px;
  margin-bottom: -1px;
  position: absolute;
  pointer-events: none;
`;

const TOP_BACKGROUND_HEIGHT = '1.48vw';
const BOTTOM_BACKGROUND_HEIGHT = '1.88vw';

export const BackgroundWrapper = styled.div`
  width: 100%;
  height: 700px;
  margin-top: -${TOP_BACKGROUND_HEIGHT};
  position: absolute;
  pointer-events: none;
  overflow: hidden;
`;

export const Background = styled.div`
  width: 100vw;
  height: 599px;
  position: absolute;
  margin-top: ${TOP_BACKGROUND_HEIGHT};
  z-index: -2;
  background-color: ${colors.biscuit};
  background-size: cover;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

  &:before,
  &:after {
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    background-size: contain;
  }
  &:before {
    height: ${TOP_BACKGROUND_HEIGHT};
    top: calc(-${TOP_BACKGROUND_HEIGHT} + 2px);
    background: url(${backgroundTop}) no-repeat center 0;

    @media screen and (max-width: ${breakpoints.lg}px) {
      margin-top: 1px;
      transform: scale(1.1);
    }
  }
  &:after {
    height: ${BOTTOM_BACKGROUND_HEIGHT};
    bottom: -${BOTTOM_BACKGROUND_HEIGHT};
    background: url(${backgroundBottom}) no-repeat center 0;

    @media screen and (max-width: ${breakpoints.lg}px) {
      transform: scale(1.1);
    }
  }
`;

export const BackgroundIllustrations = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: center;

  &:after {
    content: '';
    width: 1514px;
    height: 636px;
    position: absolute;
    top: -43px;
    background: url(${illustration}) no-repeat center 0;
    background-size: contain;
    transform: translateX(3px);
  }
`;
