import React, { ReactElement } from 'react';
import { Layout } from '../components/Layout/Layout';
import { SpellLike } from '../components/SpellLike/SpellLike';
import { Intro } from '../modules/Intro/Intro';
import { TestimonialsMore } from '../modules/Testimonials/TestimonialsMore';
import { TESTIMONIALS_DATA } from '../data/testimonials';
import { Signup } from '../modules/Signup/Signup';
import { CWPricing } from '../modules/CWScripts/CWPricing';
import { FAQ } from '../modules/FAQ/FAQ';

const WORDS = ['stunning', 'process', 'plans', 'mockups'];

const PricingMixPage = (): ReactElement => {
  return (
    <Layout>
      <Intro
        title={
          <>
            Let SketchWow be your secret-sauce for <SpellLike words={['stunning']} /> sketches, diagrams & flows!
          </>
        }
       
        withSignUp={false}
      />
      <CWPricing />
      
      <FAQ/>

      <TestimonialsMore data={TESTIMONIALS_DATA} title="You'll Love SketchWow Too, Just Like Our Customers Do..." />

      <Signup
        subTitle="With SketchWow in your corner… go create something great!"
        title="Time To Ditch The Dull Diagrams & Flowcharts..."
      />
    </Layout>
  );
};

export default PricingMixPage;
