import React, { ReactElement, useEffect, useRef } from 'react';
import "./style_pricing.css"
import * as Styled from './styles';
import { useVisibilityDetection } from '../../hooks/useVisibilityDetection';

  
export const CWPricing = (): ReactElement => {
    const pricingRef = useRef(null);
    const stickyDetectionRef = useRef(null);
    const { isVisible } = useVisibilityDetection(pricingRef, [0.1, 0.9]);
    const { isVisible: shouldHideTopBar } = useVisibilityDetection(stickyDetectionRef, [0, 1], true);

    useEffect(()=>{
        const pagestyle = `
          *{
                margin: 0;
                padding: 0;
            } 
        `

        var pageStyleSheet = document.createElement("style")
        pageStyleSheet.textContent = pagestyle
        document.head.appendChild(pageStyleSheet)
    })
  return (
    <Styled.Wrapper isVisible={isVisible} ref={pricingRef} id="pricing">

    <div className="cw-container-pricing">
         <div className="cw-bg-color">
            <div className="cw-wrapper">
                <div className="cw-logo">
                    <img src="/cwfiles/sketch-logo.png" alt=""/>
                </div>
                <div className="cw-subscription">
                    <div className="cw-price-pricing">$39<sub>/mo</sub></div>
                    <h2 className="cw-sub">Subscription</h2>
                    <h5 className="cw-bill">(Billed Monthly)</h5>
            
                </div>
                <div className="cw-subscription2-pricing">
                    <div className="cw-price-pricing">$49</div>
                    <h2 className="cw-sub">Lifetime</h2>
                    <h5 className="cw-bill">(One-time Payment)</h5>
            
                </div>
            </div>
            <ul className="cw-list-container">
                <li><div className="text">Unlimited sketches</div><div className="check-button-light"><img src="/cwfiles/check-light.png" alt=""/></div> <div className="check-button-dark"><img src="/cwfiles/check-dark.png" alt=""/></div></li>
                <li><div className="text">AI-powered mindmaps, flows, visuals</div><div className="check-button-light"><img src="/cwfiles/check-light.png" alt=""/></div> <div className="check-button-dark"><img src="/cwfiles/check-dark.png" alt=""/></div></li>
                <li><div className="text">73 templates</div><div className="check-button-light"><img src="/cwfiles/check-light.png" alt=""/></div> <div className="check-button-dark"><img src="/cwfiles/check-dark.png" alt=""/></div></li>
                <li><div className="text">350 elements, shapes, icons</div><div className="check-button-light"><img src="/cwfiles/check-light.png" alt=""/></div> <div className="check-button-dark"><img src="/cwfiles/check-dark.png" alt=""/></div></li>
                <li><div className="text">Standard color backgrounds</div><div className="check-button-light"><img src="/cwfiles/check-light.png" alt=""/></div> <div className="check-button-dark"><img src="/cwfiles/check-dark.png" alt=""/></div></li>
                <li><div className="text">30 fonts (+ import custom fonts)</div><div className="check-button-light"><img src="/cwfiles/check-light.png" alt=""/></div> <div className="check-button-dark"><img src="/cwfiles/check-dark.png" alt=""/></div></li>
                <li><div className="text">Resize canvas (Custom size)</div><div className="check-button-light"><img src="/cwfiles/check-light.png" alt=""/></div> <div className="check-button-dark"><img src="/cwfiles/check-dark.png" alt=""/></div></li>
                <li><div className="text">Import Images (Drag-and-drop)</div><div className="check-button-light"><img src="/cwfiles/check-light.png" alt=""/></div> <div className="check-button-dark"><img src="/cwfiles/check-dark.png" alt=""/></div></li>
                <li><div className="text">JPG, PNG, SVG, PDF, Animated GIF (Export)</div><div className="check-button-light"><img src="/cwfiles/check-light.png" alt=""/></div> <div className="check-button-dark"><img src="/cwfiles/check-dark.png" alt=""/></div></li>
                <li><div className="text">Presentation mode</div><div className="check-button-light"><img src="/cwfiles/check-light.png" alt=""/></div> <div className="check-button-dark"><img src="/cwfiles/check-dark.png" alt=""/></div></li>
                <li><div className="text">File backup & restore</div><div className="check-button-light"><img src="/cwfiles/check-light.png" alt=""/></div> <div className="check-button-dark"><img src="/cwfiles/check-dark.png" alt=""/></div></li>
                <li><div className="text">Use on multiple computers</div><div className="check-button-light"><img src="/cwfiles/check-light.png" alt=""/></div> <div className="check-button-dark"><img src="/cwfiles/check-dark.png" alt=""/></div></li>
                <li><div className="text">Standard support</div><div className="check-button-light"><img src="/cwfiles/check-light.png" alt=""/></div> <div className="check-button-dark"><img src="/cwfiles/check-dark.png" alt=""/></div></li>
            
            </ul>
            <div className="button-wrapper">
                <div className="empty-box"></div>
                <div className="startedbutton"><a href="https://checkout.sketchwow.com/?add-to-cart=816352&swp=home"><button type="button">get started</button></a></div>
                <div className="startedbutton active"><a href="https://checkout.sketchwow.com/?add-to-cart=407&cg2=1&swp=home"><button type="button">get started</button></a></div>
            </div>
            </div>
    </div>
            <Styled.BackgroundWrapper>
            <Styled.Background>
              <Styled.BackgroundIllustrations />
            </Styled.Background>
          </Styled.BackgroundWrapper>
              </Styled.Wrapper>      
  );

  
};
